import React from 'react';
import { FiGithub } from "react-icons/fi";
import { FaXTwitter } from "react-icons/fa6";
import { FiLinkedin } from "react-icons/fi";
import { FaMedium } from "react-icons/fa6";

const RetroFooter = () => {
  const socialLinks = [
    {
      name: 'GITHUB',
      icon: <FiGithub size={24} />,
      url: 'https://github.com/WankioM',
      position: 'translate-y-0'
    },
    {
      name: 'LINKEDIN',
      icon: <FiLinkedin size={24} />,
      url: 'https://www.linkedin.com/in/tracy-wankio-software-developer/',
      position: 'translate-y-4'
    },
    {
      name: 'X',
      icon: <FaXTwitter size={24} />,
      url: 'https://x.com/TWankio',
      position: 'translate-y-8'
    },
    {
      name: 'MEDIUM',
      icon: <FaMedium size={24} />,
      url: 'https://medium.com/@tracywankio29',
      position: 'translate-y-12'
    }
  ];

  return (
    <footer className="relative border-t border-school-bus/30 bg-raisin">
      {/* Retro Grid Lines */}
      <div className="absolute inset-0 overflow-hidden opacity-10">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute h-px w-full bg-school-bus"
            style={{ top: `${i * 5}%` }}
          />
        ))}
      </div>

      <div className="relative mx-auto max-w-7xl px-6 py-12">
        {/* Main Footer Content */}
        <div className="flex flex-col md:flex-row justify-between items-start gap-12">
          {/* Left Side - Social Links */}
          <div className="space-y-8">
            <h2 className="font-kelly text-4xl text-school-bus transform -rotate-2">
              LET'S CONNECT
            </h2>
            <div className="flex flex-col space-y-6">
              {socialLinks.map((link) => (
                <a
                  key={link.name}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`group flex items-center space-x-4 ${link.position} hover:translate-x-2 transition-all duration-300`}
                >
                  <span className="text-reseda group-hover:text-school-bus transition-colors">
                    {link.icon}
                  </span>
                  <span className="font-kelly text-lg text-reseda group-hover:text-school-bus transition-colors">
                    {link.name}
                  </span>
                </a>
              ))}
            </div>
          </div>

          {/* Right Side - Random Decorative Elements */}
          <div className="flex flex-col items-end space-y-4">
            <div className="font-kelly text-right">
              <p className="text-reseda text-sm transform rotate-2">DESIGNED AND BUILT BY</p>
              <p className="text-school-bus text-xl transform -rotate-1">TRACY WANKIO</p>
            </div>
            
            {/* Retro Decorative Element */}
            <div className="flex items-center space-x-2">
              <div className="h-1 w-12 bg-school-bus transform rotate-45" />
              <div className="h-1 w-8 bg-reseda transform -rotate-45" />
              <div className="h-1 w-4 bg-school-bus transform rotate-45" />
            </div>
          </div>
        </div>

        {/* Bottom Copyright */}
        <div className="mt-12 pt-8 border-t border-reseda/20">
          <p className="font-helvetica-light text-sm text-reseda text-center md:text-left transform hover:translate-x-2 transition-transform">
            © {new Date().getFullYear()} / BUILT WITH REACT & TAILWIND
          </p>
        </div>
      </div>
    </footer>
  );
};

export default RetroFooter;