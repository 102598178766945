import React from 'react';
import BgModel from './Home';

const RetroLandingPage = () => {
  const handleDownload = () => {
    window.open('/TracyWankio_Developer.pdf', '_blank');
  };

  return (
    <div className="relative min-h-screen w-full overflow-hidden">
      {/* 3D Background */}
      <div className="fixed inset-0 w-full h-full">
        <BgModel />
      </div>

      {/* Content Overlay - Now with pointer-events-none */}
      <div className="relative z-10 min-h-screen flex flex-col pointer-events-none">
        {/* Gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#342E37]/40 to-[#342E37]/10" />

        {/* Header - Enable pointer events */}
        <header className="relative z-20 flex items-center justify-between p-8 pointer-events-auto">
          {/* Logo */}
          <div className="w-24 h-24 relative hover:scale-105 transition-transform">
            <img
              src="https://storage.googleapis.com/web-vids/yellow_me_crypto.png"
              alt="Tracy Wankio"
              className="w-full h-full object-contain"
            />
          </div>

          {/* Navigation */}
          <nav className="space-x-12">
            {['WORK', 'ABOUT', 'CONTACT'].map((item) => (
              <button 
                key={item}
                className="font-kelly text-base tracking-wider text-[#82846D] hover:text-[#F4D405] hover:-translate-y-0.5 transition-all"
              >
                {item}
              </button>
            ))}
          </nav>
        </header>

        {/* Main Content - Enable pointer events only on interactive elements */}
        <main className="relative z-20 flex-1 flex flex-col items-start justify-center px-8 md:px-16 py-12">
          <div className="max-w-4xl space-y-12">
            <div className="space-y-2">
              <h1 className="font-kelly text-7xl md:text-9xl leading-none text-[#F4D405] mix-blend-difference">
                HELLO, I'M
                <br />
                TRACY WANKIO
              </h1>
              
              <div className="space-y-6 mt-8">
                <p className="font-kelly text-2xl text-[#F4D405] max-w-xl">
                  SOFTWARE DEVELOPER
                </p>
                <p className="font-helvetica-light text-lg text-white max-w-xl leading-relaxed backdrop-blur-sm bg-[#342E37]/20 p-4">
                🛠️ Blockchain-powered DeFi, 🖥️ end-to-end full-stack development, 🎨 dynamic 3D interactions, and ✨ intuitive UI/UX.
                </p>
              </div>
            </div>

            <button 
              onClick={handleDownload}
              className="pointer-events-auto group relative overflow-hidden px-8 py-4 bg-transparent border border-[#F4D405] hover:bg-[#F4D405] transition-all duration-300 hover:scale-105"
            >
              <span className="font-kelly text-[#F4D405] group-hover:text-[#342E37] transition-colors">
                DOWNLOAD CV
              </span>
            </button>
          </div>
        </main>


       
      </div>
    </div>
  );
};

export default RetroLandingPage;