import React from 'react';
import { FaPython, FaReact, FaEthereum } from "react-icons/fa";
import { SiSolidity, SiNextdotjs, SiThreedotjs, SiGooglecloud, SiAmazonaws, SiKubernetes } from "react-icons/si";
import { TbBrandThreejs } from "react-icons/tb";

const BioSkillsSection = () => {
  const techIcons = [
    { icon: <FaPython />, name: "Python" },
    { icon: <FaReact />, name: "React" },
    { icon: <SiNextdotjs />, name: "Next.js" },
    { icon: <SiThreedotjs />, name: "Three.js" },
    { icon: <SiSolidity />, name: "Solidity" },
    { icon: <FaEthereum />, name: "Ethereum" }
  ];

  return (
    <section className="bg-raisin py-24 relative overflow-hidden">
      {/* Background Grid */}
      <div className="absolute inset-0 opacity-5">
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="absolute w-full h-px bg-white"
            style={{ top: `${i * 5}%`, transform: 'rotate(-2deg)' }}
          />
        ))}
      </div>

      <div className="max-w-7xl mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          {/* Left Column - Bio */}
          <div className="space-y-8">
            <div className="space-y-4">
              <h2 className="font-kelly text-3xl text-school-bus transform -rotate-1">
                FULL-STACK ENGINEER
              </h2>
              <div className="flex gap-4">
                <div className="bg-[#2A2529] px-4 py-2 border border-reseda/20">
                  <span className="font-helvetica-light text-white text-sm">Google Cloud Certified</span>
                </div>
                <div className="bg-[#2A2529] px-4 py-2 border border-reseda/20">
                  <span className="font-helvetica-light text-white text-sm">AWS Certified</span>
                </div>
              </div>
            </div>

            <p className="font-helvetica-light text-reseda leading-relaxed">
              Building innovative and immersive web applications with a passion for exceeding client expectations.
              I'm a tech generalist diving into the world of Blockchain! 🚀
            </p>

            <div className="relative border-l-2 border-school-bus pl-6 space-y-4">
              <p className="font-helvetica-light text-white">Currently exploring:</p>
              <ul className="space-y-2 text-reseda">
                <li className="flex items-center gap-2">
                  <span className="h-1 w-1 bg-school-bus"></span>
                  Cairo 🏛️ and StarkNet's Layer 2 scaling
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-1 w-1 bg-school-bus"></span>
                  The Graph for efficient data querying
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-1 w-1 bg-school-bus"></span>
                  Flutter & Base development
                </li>
              </ul>
            </div>
          </div>

          {/* Right Column - Skills */}
          <div className="space-y-12">
            {/* Expertise Grid */}
            <div className="space-y-6">
              <h3 className="font-mono text-white text-lg">EXPERTISE</h3>
              <div className="grid grid-cols-2 gap-4">
                {[
                  "Frontend Development",
                  "Backend Development",
                  "Cloud Computing",
                  "3D Design & Animation",
                  "DevOps",
                  "Blockchain"
                ].map((skill, index) => (
                  <div
                    key={index}
                    className="bg-[#2A2529] p-4 border border-reseda/20 transform hover:-translate-y-1 transition-transform"
                  >
                    <span className="font-helvetica-light text-reseda text-sm">{skill}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Technologies */}
            <div className="space-y-6">
              <h3 className="font-mono text-white text-lg">TECHNOLOGIES I LOVE</h3>
              <div className="flex flex-wrap gap-4">
                {techIcons.map((tech, index) => (
                  <div
                    key={index}
                    className="group flex items-center gap-2 bg-[#2A2529] px-4 py-2 border border-reseda/20 hover:border-school-bus transition-colors"
                  >
                    <span className="text-reseda group-hover:text-school-bus transition-colors">
                      {tech.icon}
                    </span>
                    <span className="font-helvetica-light text-reseda group-hover:text-school-bus transition-colors text-sm">
                      {tech.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>

            {/* Fun Fact */}
            <div className="relative bg-[#2A2529] p-6 border border-reseda/20">
              <div className="absolute top-0 right-0 transform -translate-y-1/2 translate-x-1/2 rotate-12">
                <div className="font-kelly text-school-bus text-sm">FUN FACT</div>
              </div>
              <p className="font-helvetica-light text-reseda italic">
                "My favorite coding language is whichever one doesn't require me to debug at 3 AM!"
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BioSkillsSection;