import React, { useState } from 'react';
import { FiExternalLink, FiGithub, FiChevronLeft, FiChevronRight } from "react-icons/fi";

const ProjectsSection = () => {
  const [hoveredProject, setHoveredProject] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const projectsPerPage = 6;

  const projects = [
    {
      title: "DAOBITAT",
      description: "DAO-Bitat is a revolutionary platform designed to bring global innovation to the real estate and SME investment landscapes in Africa through the power of asset tokenization. Enables property ownership to be tokenized and verified on-chain, allowing co-ownership, collective decision-making, and democratized access to assets.",
      category: "WEB3",
      link: "https://www.daobitat.xyz/",
      coverImage: "https://storage.googleapis.com/web-vids/daobitat_lp.png",
      type: "image"
    },
    {
      title: "VAULT89",
      description: "Vault89 is the banner for three companies aiming to bring forth empathic solutions to complex problems. An impact investment firm committed to shaping the future of social entrepreneurship, with consulting and real estate divisions working towards positive community impact.",
      category: "WEBSITE",
      link: "https://vault89.com/",
      coverImage: "https://storage.googleapis.com/web-vids/vault89_lp.png",
      type: "image"
    },

    {
      title: "SANIDI",
      description: `An intuitive Wagmi code generator that simplifies Web3 integration for developers in both Swahili and English. 
Progressive guidance through chain selection, API configuration, and client setup, making blockchain development accessible to everyone.`,
      category: "WEB APP",
      link: "https://sanidilab.vercel.app/",
      coverImage: "https://storage.googleapis.com/web-vids/Screenshot%202024-11-14%20002030.png",
      type: "image"
    },

    {
      title: "NATIVE CODERS",
      description: "We offer a powerful blend of strategic thinkers, creative designers, disruptive coders, insightful data engineers and other brilliant minds.",
      category: "WEBSITE",
      link: "https://www.nativecoders.se/",
      coverImage: "https://storage.googleapis.com/web-vids/nativecoders_lp.png",
      type: "image"
    },
    {
      title: "3D PORTFOLIO",
      description: "A collection of my 3D work done on Blender, featuring renders, animations, and product mockups that showcase various techniques and styles in 3D design.",
      category: "3D",
      link: "https://tracywankio6.artstation.com/",
      coverImage: "https://storage.googleapis.com/web-vids/car.mp4",
      type: "video"
    },
    {
      title: "Sober Token",
      description: "A collection of projects (under DV Tech, in collaboration with DV Tech Team) focused on mental health, addiction. run by cypto (sober tokens, St Newfie) used ncentivize the ecossytem and allow external donor participation",
      category: "WEB3",
      link:"https://snewf.dvtechventures.com/",
      coverImage: "https://storage.googleapis.com/web-vids/dvtech.png",
      type: "image"
    },
    {
      title: "MANA STATS",
      description: "A Project made on the graph to enhance the visibility of decentraland auctions",
      category: "FULL STACK",
      github: "https://github.com/WankioM/trib",
      coverImage: "https://storage.googleapis.com/web-vids/manastats.png",
      type: "image"
    },
    {
      title: "OMORFIA",
      description: "Website for cosmetic office with appoint booking capabilities",
      category: "WEBSITE",
      link: "https://omorfia-three.vercel.app",
      coverImage: "https://storage.googleapis.com/web-vids/omorfia.png",
      type: "image"
    },
    {
      title: "NA Kenya",
      description: "Website for narcotics anonymous kenya with event list and booking",
      category: "WEBSITE",
      link: "https://www.nakenya.com/",
      coverImage: "https://storage.googleapis.com/web-vids/nakenya.png",
      type: "image"
    }
  ];

  const totalPages = Math.ceil(projects.length / projectsPerPage);
  const currentProjects = projects.slice(
    currentPage * projectsPerPage,
    (currentPage + 1) * projectsPerPage
  );

  const nextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const prevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  const MediaComponent = ({ project }) => {
    if (project.type === "video") {
      return (
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover"
        >
          <source src={project.coverImage} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return (
      <img
        src={project.coverImage}
        alt={project.title}
        className="w-full h-full object-cover"
      />
    );
  };

  return (
    <section className="bg-raisin/90 backdrop-blur-sm py-20">
      {/* Section Header */}
      <div className="max-w-7xl mx-auto px-6 mb-16">
        <h2 className="font-kelly text-6xl text-white mb-4 transform -rotate-1">
          SELECTED WORKS
        </h2>
        <p className="font-helvetica-light text-reseda max-w-2xl">
          A collection of projects spanning web development, 3D design, and blockchain technology.
        </p>
      </div>

      {/* Projects Grid */}
      <div className="max-w-7xl mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {currentProjects.map((project, index) => (
            <div
              key={index}
              className="group relative bg-[#2A2529] border border-reseda/20 p-6 transform transition-all duration-300 hover:-translate-y-2"
              onMouseEnter={() => setHoveredProject(index)}
              onMouseLeave={() => setHoveredProject(null)}
            >
              {/* Project Image/Video */}
              <div className="relative h-48 mb-6 overflow-hidden">
                <div className="absolute inset-0 bg-school-bus/10" />
                <MediaComponent project={project} />
              </div>

              {/* Category Tag */}
              <div className="inline-block font-kelly text-xs text-reseda border border-reseda/30 px-3 py-1 mb-4">
                {project.category}
              </div>

              {/* Project Title */}
              <h3 className="font-kelly text-2xl text-white mb-4 transform group-hover:translate-x-1 transition-transform">
                {project.title}
              </h3>

              {/* Project Description */}
              <p className="font-helvetica-light text-sm text-reseda/80 mb-6 line-clamp-3">
                {project.description}
              </p>

              {/* Links */}
              <div className="flex items-center space-x-4">
                {project.link && (
                  <a
                    href={project.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-white hover:text-school-bus transition-colors"
                  >
                    <FiExternalLink />
                    <span className="font-kelly text-sm">VISIT</span>
                  </a>
                )}
                {project.github && (
                  <a
                    href={project.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-white hover:text-school-bus transition-colors"
                  >
                    <FiGithub />
                    <span className="font-kelly text-sm">CODE</span>
                  </a>
                )}
              </div>

              {/* Decorative Corner */}
              <div className="absolute top-0 right-0 w-8 h-8 transform rotate-45 bg-school-bus/10 -translate-y-1/2 translate-x-1/2" />
            </div>
          ))}
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-center items-center space-x-4 mt-12">
          <button
            onClick={prevPage}
            className="p-2 text-white hover:text-school-bus transition-colors"
            aria-label="Previous page"
          >
            <FiChevronLeft size={24} />
          </button>
          <span className="font-kelly text-white">
            {currentPage + 1} / {totalPages}
          </span>
          <button
            onClick={nextPage}
            className="p-2 text-white hover:text-school-bus transition-colors"
            aria-label="Next page"
          >
            <FiChevronRight size={24} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;