
import './App.css';
import RetroLandingPage from './components/LandingPage';

import { Services } from './Services';

import { NewProjects } from './Newprojects';
import RetroFooter from './components/Footer';
import ProjectsSection from './components/Projects';
import BioSkillsSection from './components/BioSkills';


function App() {
  return (
    <div className="App">
     
      <RetroLandingPage />
    
      <ProjectsSection />
      <BioSkillsSection />
      <RetroFooter />
       
     
     
    </div>
  );
}

export default App;
